.label {
  display: flex;
  align-items: center;

  &, a {
    color: var(--nav-accent-color);
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1;

    transition: 150ms opacity ease-out;

    &:hover, &.active {
      cursor: pointer;
      opacity: 0.5;
    }
  }
}

.productMenu {
  span {
    position: relative;
  }
}

.hoverMenuContainer {
  position: absolute;
  display: block;
  top: 1.75rem;
  right: 0;
  pointer-events: none;
  opacity: 0;
  transition: 300ms all ease-out;
  perspective: 2000px;
  will-change: transform, opacity;

  &.active {
    pointer-events: unset;
    opacity: 1;
  }

  &:before {
    --side: 1.25rem;
    content: '';
    position: absolute;
    height: var(--side);
    width: var(--side);
    background-color: #EBEDF0;
    top: calc(0.25 * var(--side));
    right: 0;
    // The subtracted value is around half of the hypotenuse of the square.
    margin-right: calc(var(--offset) - 0.85rem);
    transform: rotate(45deg);
  }
}

.hoverMenu {
  position: relative;
  background-color: #ffffff;
  border: 0.225rem solid #F3F5F6;
  overflow: hidden;

  display: grid;
  grid-template-columns: 16rem 1fr;
  width: 55rem;

  margin-top: 0.75rem;

  transform: rotateX(-15deg);
  transform-origin: 50% -50px;
  transition: 300ms all ease-out;

  &.active {
    transform: rotateX(0deg);
  }

  border-radius: 1rem;
  list-style: none;

  box-shadow:
    0px 1.9px 2.2px rgba(0, 0, 0, 0.014),
    0px 4.6px 5.3px rgba(0, 0, 0, 0.02),
    0px 8.6px 10px rgba(0, 0, 0, 0.025),
    0px 15.4px 17.9px rgba(0, 0, 0, 0.03),
    0px 28.8px 33.4px rgba(0, 0, 0, 0.036),
    0px 69px 80px rgba(0, 0, 0, 0.05);
}

.sectionMenu {
  padding: 0;
  list-style: none;
  background-color: #F3F5F6;
}

.sectionMenuItem {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  margin: 0.5rem;

  border-radius: 0.75rem;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;

  user-select: none;

  transition: 150ms background-color ease-out;

  button {
    background: none;
    outline: none;
    border: none;
    text-align: inherit;
  }

  &:hover, &.activeSection {
    background-color: white;
    cursor: pointer;
  }

  h4 {
    margin: 0;
    margin-bottom: 0.15rem;
    font-size: 0.875rem;
    font-weight: 600;
  }

  p {
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.35rem;
    opacity: 0.75;
  }
}

.sectionCollections {
  list-style: none;
  padding: 0;
  padding-top: 2rem;
  padding-bottom: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;

  li:only-of-type {
    grid-column: span 2;

    > ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      column-gap: 2rem;
    }
  }

  li > h4 {
    margin-top: 0;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: rgba(#4E5D69, 0.75);
    font-weight: 700;

    margin-left: 3rem;
    margin-bottom: 1rem;
  }

  li.firstColumn > h4 {
    margin-left: 3rem;
  }
}

.collectionItemsList {
  padding: 0;
  list-style: none;
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;

  padding: 0rem 0rem;
}

.collectionItem {
  a {
    display: grid;
    grid-template-columns: 2.25rem 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: 
      'icon title'
      'icon description';

    column-gap: 1rem;
    row-gap: 0.15rem;
  }

  .iconContainer {
    position: relative;
    grid-area: icon;
    aspect-ratio: 1 / 1;
    margin-top: 0.25rem;
    transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transition: 275ms all cubic-bezier(.51,-0.72,.25,1.75);
    will-change: transform;
  }

  &:hover {
    cursor: pointer;

    .iconContainer {
      transform: perspective(500px) rotateX(-30deg) rotateY(30deg) rotateZ(15deg);
    }

    p {
      color: rgba(#4E5D69, 1);
    }

    h4:after {
      transform: scaleX(1);
      opacity: 1;
    }
  }

  .title {
    grid-area: title;
  }

  .description {
    grid-area: description;
  }

  .chips {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    column-gap: 0.35rem;

    margin-top: 0.45rem;
    margin-bottom: 0.25rem;

    .chip {
      display: inline-flex;
      font-size: 0.75rem;
      font-weight: 600;
      color: rgba(#4E5D69, 0.8);
      background-color: #F2F2F2;

      padding: 0.08rem 0.525rem;
      border-radius: 100rem;
    }
  }

  h4 {
    font-size: 0.875rem;
    margin: 0;
    display: flex;
    align-items: center;
    transition: 200ms all ease-out;
    font-weight: 600;
    line-height: 1.25;

    &:after {
      content: 'arrow_forward';
      font-family: var(--font-material-icons);
      line-height: 1;
      font-size: 0.875rem;

      margin-left: 0.35rem;
      margin-bottom: 0rem;
      transform: scaleX(0);
      opacity: 0;

      transition: 200ms all ease-out;
      will-change: transform, opacity;
    }
  }

  p {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.35rem;
    color: rgba(#4E5D69, 0.65);
    transition: 200ms all ease-out;
  }
}
