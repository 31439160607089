.footer {
  height: max-content;
  background: #f8f8f8;

  > div:first-of-type {
    max-width: var(--section-max-width);
    padding: 4rem 2rem;

    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;

    column-gap: 5rem;
    row-gap: 2rem;
  }

  aside {
    display: grid;
    grid-auto-flow: row;
    align-content: start;

    p {
      font-size: 0.9375rem;
      max-width: 25rem;
    }
  }

  .sections {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    column-gap: 2rem;

    section {
      > h4 {
        font-size: 0.9375rem;
        margin-bottom: 0.75rem;
      }

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    display: grid;
    row-gap: 0.5rem;

    li a {
      color: rgba(#4e5d69, 0.8);
      font-size: 0.9375rem;

      &:hover {
        color: rgba(#4e5d69, 1);
      }
    }
  }
}

.copyrightWrapper {
  background-color: #e8e8e8;
  padding: 1rem 4rem;
}

.copyright {
  display: flex;
  align-items: center;
  margin: 0rem;

  font-size: 0.875rem;
}

.logoContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 3rem;
}

.materialIcons {
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  position: relative;
  margin-right: 0.25rem;

  &:after {
    font-family: var(--font-material-icons);
    font-weight: normal;
    font-style: normal;
    font-size: 1rem; /* Preferred icon size */
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}

@media (max-width: 750px) {
  .footer {
    > div:first-of-type {
      padding: 2rem 1rem;
      padding-right: 2rem;
      column-gap: 2rem;
    }

    aside {
      p {
        max-width: 25rem;
      }
    }

    .sections {
      grid-auto-flow: row;
    }
  }

  .copyrightWrapper {
    padding: 1rem;
  }

  .copyright {
    align-self: flex-end;
  }
}

@media (max-width: 600px) {
  .footer {
    > div:first-of-type {
      grid-auto-flow: row;
      padding-bottom: 0;
    }

    .sections {
      grid-auto-flow: column;
      justify-content: space-between;
      column-gap: 2rem;
    }
  }
}

@media (max-width: 400px) {
  .footer {
    > div:first-of-type {
      padding-bottom: 2rem;
    }

    .sections {
      grid-auto-flow: row;
      justify-content: start;
      column-gap: 2rem;
    }

    .copyrightWrapper {
      padding: 1rem;
    }
  }
}
