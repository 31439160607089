.nav {
  display: flex;
  justify-content: center;
  height: 3.75rem;
  
  position: absolute;
  width: 100%;
  z-index: 1000;
  backdrop-filter: blur(5px);
  backface-visibility: hidden;
  transform: translateZ(0);

  padding-left: 1rem;
  padding-right: 1rem;
}

.container {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: auto 1fr;
  
  padding-top: 1rem;
  padding-bottom: 1rem;
  max-width: var(--section-max-width);
}

.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;

  & >li:not(:first-of-type) {
    margin-left: 2rem;
  }
}

.label {
  display: flex;
  align-items: center;

  &, a {
    color: var(--nav-accent-color);
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1;

    transition: 150ms opacity ease-out;

    &:hover, &.active {
      cursor: pointer;
      opacity: 0.5;
    }
  }
}

.smallMenuNav {
  display: none;
  justify-content: flex-end;
  align-items: center;

  .smallMenuButton {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10rem;

    background-color: var(--nav-accent-color);
    transition: all 150ms ease-out;

    &:hover {
      opacity: 0.8;
    }

    div {
      display: flex;
    }
  }
}

.materialIcons {
  display: block;

  &:after {
    padding: 0.4rem 0.9rem;
    border-radius: 1000rem;

    color: white;

    position: relative;
    font-family: var(--font-material-icons);
    font-weight: normal;
    font-style: normal;
    font-size: 1.35rem;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }
}

@media (max-width: calc(55rem + 3rem)) {
  .menu {
    display: none;
  }

  .smallMenuNav {
    display: flex;
  }
}
